

  .truncated-html {
    display: -webkit-box;           /* Necessario per -webkit-line-clamp */
    -webkit-box-orient: vertical;   /* Imposta l'orientamento verticale */
    overflow: hidden;

    -webkit-line-clamp: 5;          /* Limita a 5 righe per WebKit */
    line-clamp: 5;                  /* Proprietà standard per compatibilità futura */
}

  .separator {
    width: 100%;
    height: 1px;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 1px solid #0d11e9;
    margin: 0px;
    padding: 0px;
  }