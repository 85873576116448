

  .button-row {
    display: flex;
    position: relative;
  }

  .controls-row {
    position: relative;
  }
  
  .past-flex-button {
    flex: 1;
    margin: 0px;
    padding: 0px;
    border:1px solid #090000; 
    background-color: #007bff;
    color: white;
    cursor: pointer;
    text-align: center;
  }

  .flex-button {
    flex: 1;
    margin: 0px;
    padding: 0px;
    border:1px solid #090000; 
    background-color: #a9b2be;
    color: white;
    cursor: pointer;
    text-align: center;
  }
  
  .flex-button:hover {
    background-color: #0056b3;
  }

  .label-hover-effect {
    color: rgb(14, 1, 1); /* Colore del testo predefinito */
    transition: color 0.3s ease; /* Transizione per un cambiamento di colore più fluido */
}

.label-hover-effect:hover {
    color: rgb(74, 79, 207); /* Colore del testo al passaggio del mouse */
}

.extraClass {
  font-size: 20px !important;
  pointer-events: auto !important;
  &:hover {
 visibility: visible !important;
 opacity: 1 !important;
  }
 }

 .spinner-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
}

.radio-container {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content:flex-start;
  margin-top: 20px;
  padding-right: 50px;
}

.radio-label {
  padding-left: 10px; /* Increased margin for better spacing */
}

.custom-radio {
  margin-right: 10px;
  font-size: 1.5rem; /* Increase font size */
}

.custom-radio input {
  transform: scale(1.5); /* Scale the radio button itself */
  margin-right: 20px; /* Space between the radio button and the label */
  margin-top: 10px;
}


.mobile-radio-container {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content:flex-start;
  margin-top: 10px;
  padding-right: 25px;
}

.mobile-radio-label {
  padding-left: 10px; /* Increased margin for better spacing */
}

.mobile-custom-radio {
  margin-right: 10px;
  font-size: 1.0rem; /* Increase font size */
}

.mobile-custom-radio input {
  transform: scale(1.0); /* Scale the radio button itself */
  margin-right: 20px; /* Space between the radio button and the label */
  margin-top: 5px;
}


.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}


.video-container {
  position: relative;
  width: 100%;
  height: auto;
  min-height: "50vh";
}

.mobile-video-container {
  position: relative;
  width: 100%;
  height: auto;
  -webkit-touch-callout: none; /* Disabilita il menu di contesto su iOS */
  -webkit-user-select: none;   /* Impedisci la selezione su iOS */
  -moz-user-select: none;      /* Impedisci la selezione su Firefox */
  -ms-user-select: none;       /* Impedisci la selezione su Internet Explorer */
  user-select: none;           /* Impedisci la selezione in tutti i browser */
}


.video-logo {
  position: absolute;
  top: 10px; /* Regola la posizione verticale */
  right: 10px; /* Regola la posizione orizzontale */
  width: 15%; /* Regola la dimensione del logo */
  opacity: 0.0; /* Imposta l'opacità per renderlo semi-trasparente */
  z-index: 10; /* Assicurati che il logo sia sovrapposto al video */
}

video {
  pointer-events: none; /* Disabilita il tocco sugli elementi video */
}

.overlay {
  position: absolute;
  height: 400px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* Overlay semi-trasparente */
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
  text-align: center;
  pointer-events: none; /* Impedisce l'interazione */
  z-index: 2;
}

.overlay-text {
  padding: 20px;
}


 