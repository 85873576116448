.splitter-layout {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .splitter-layout .layout-pane {
    position: relative;
    flex: 0 0 auto;
    overflow: auto;
  }
  
  .splitter-layout .layout-pane.layout-pane-primary {
    flex: 1 1 auto;
  }
  
  .splitter-layout > .layout-splitter {
    flex: 0 0 auto;
    width: 6px;
    height: 100%;
    cursor: col-resize;
    background-color: #ddd;
    box-shadow: 1px 0 3px rgba(0, 0, 0, 0.3);
  }
  
  .splitter-layout .layout-splitter:hover {
    background-color: #bbb;
  }
  
  .splitter-layout.layout-changing {
    cursor: col-resize;
  }
  
  .splitter-layout.layout-changing > .layout-splitter {
    background-color: #aaa;
  }
  
  .splitter-layout.splitter-layout-vertical {
    flex-direction: column;
  }
  
  .splitter-layout.splitter-layout-vertical.layout-changing {
    cursor: row-resize;
  }
  
  .splitter-layout.splitter-layout-vertical > .layout-splitter {
    width: 100%;
    height: 40px;
    cursor: row-resize;
  }
  