.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.d-flex {
  display: flex!important;
  height: 80vh;
}

.center {
  margin: 0 auto;
  align-self: center;
}

/* make the customizations */
$theme-colors: (
  // "info": tomato,
  // "danger": teal,
  "breadcrumb" : #e9ecef
);

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),    //4px
    2: ($spacer * .5),     //8px
    3: $spacer,            //16px
    4: ($spacer * 1.5),    //24px
    5: ($spacer * 3),      //48px
    6: ($spacer * 4),      //64px
    7: ($spacer * 5),      //80px
    8: ($spacer * 6.25),   //100px
    9: ($spacer * 7.5),    //120px
    10: ($spacer * 9.375), //150px
    15: ($spacer * 18)     //300px
  ),
  $spacers
  );

.form-control:disabled, .form-control[readonly] {
  background-color: rgba(249, 249, 249, 255) !important;
}

@import "~bootstrap/scss/bootstrap";

.tlaComponent {
  height: 600px;
  float: left;
  margin-right: 6px;
  border-width: 1px;
  border-color: map-get($theme-colors, "primary");
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}


