.out-of-range {
    background-color: rgba(88, 76, 13, 0.5)!important;
  }

.sticky {
    position: sticky;
    top: 0;
    z-index: 2000;
  }
  
  
  .lunch {
    background-color: rgba(255, 0, 0, 0.5) !important; /* we need to overwrite the weekend rule */
  }


