
.account-menu {
    position: absolute;
    top: 48px;
    text-align: left;
    right: 0;
    z-index: 100;
    background-color: white;
    border: 0px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .account-button {
    padding: 0px;
    color: black;
    width: 35px;         /* Larghezza del pulsante */
    height: 35px;        /* Altezza del pulsante */
    cursor: pointer;
  }

  .button-font {
    font-weight: bold;
    border: 0px;
    text-align: left;
  }

  .separator {
    width: 100%;
    height: 0px;
    border: 1px solid #ccc;
    margin: 0px;
    padding: 0px;
  }
  
  .round-button {
    background-color: orange;
    padding: 0px;
    color: white;
    border: none;
    border-radius: 50%;  /* Rende il pulsante rotondo */
    width: 40px;         /* Larghezza del pulsante */
    height: 40px;        /* Altezza del pulsante */
    font-size: 24px;     /* Dimensione del testo */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  