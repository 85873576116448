html, body {
    height: 100%;
    margin: 0;
  }

  .messageBox {
    color:brown
  }
  .wrapper {
    height: 100%;
  }
  .header {
    height: 50px;
    cursor: move;
    padding-top: 10px;
    background: "#007bff";
  }
  .footer {
    height: 50px;
    background: silver;
    margin-bottom: 20px;
  }
  .content {
    height: calc(100% - 140px);
    overflow: auto;
    overscroll-behavior: contain;
    background: rgb(252, 248, 249);
    margin-bottom: 10px;
    margin-top: 10px;
    margin-right: 4px;
    margin-left: 4px;
  }


  .loading {
    font-size: "1.2rem";
  }
  
  .loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4,end) 900ms infinite;      
    animation: ellipsis steps(4,end) 900ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
  }

  @keyframes ellipsis {
    to {
      width: 1.0em;    
    }
  }
  
  @-webkit-keyframes ellipsis {
    to {
      width: 1.0em;    
    }
  }