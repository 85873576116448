
  
  /** Speech bubble */
  
  .speech-bubble {
    background: #efefef;
    border-radius: 4px;
    font-size: 1.2rem;
    line-height: 1.3;
    
    margin-left: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 400px;


    max-width: 400px;
    padding: 15px;
    position: relative;
  
    p {
      margin: 0 0 10px;
  
      :last-of-type {
        margin-bottom: 0;
      }
    }
  
    &::after {
      border-left: 20px solid transparent;
      border-top: 20px solid #efefef;
      bottom: -20px;
      content: "";
      position: absolute;
      right: 20px;
    }
  }
  
  /** Speech bubble with drop shadow and border */
  
  .speech-bubble-ds {
    color: #000080; 
    background: #eec7d4;
    border: 1px solid #a7a7a7;
    border-radius: 4px;
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
    font-size: 1.0rem;
    line-height: 1.3;
    margin: 0 10px 10px 40px;
    max-width: 400px;
    padding: 15px;
    position: relative;
  
    p {
      margin-bottom: 10px;
  
      :last-of-type {
        margin-bottom: 0;
      }
    }
  }
  
  .speech-bubble-ds__arrow {
    border-left: 21px solid transparent;
    border-top: 20px solid rgba(0, 0, 0, 0.2);
    bottom: -25px;
    position: absolute;
    right: 15px;
  
    &::before {
      border-left: 23px solid transparent;
      border-top: 23px solid #a7a7a7;
      bottom: 2px;
      content: "";
      position: absolute;
      right: 5px;
    }
    &::after {
      border-left: 21px solid transparent;
      border-top: 21px solid #efefef;
      bottom: 4px;
      content: "";
      position: absolute;
      right: 6px;
    }
  }

  /** SINISTRA **/

  .speech-bubble-sx {
    color: #000080; 
    background: #efefef;
    border: 1px solid #a7a7a7;
    border-radius: 4px;
    box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.2);
    font-size: 1.0rem;
    line-height: 1.3;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 15px;
    position: relative;
  
    p {
      margin-bottom: 10px;
  
      :last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .speech-bubble-sx__arrow {
    border-right: 21px solid transparent;
    border-top: 20px solid rgba(0, 0, 0, 0.2);
    bottom: -25px;
    position: absolute;
    left: 15px;
  }
  
  