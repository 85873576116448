.customTheme {
    color: #ff6e00 !important;
    background-color: rgb(234, 226, 210) !important;
    &.place-top {
    &:after {
    border-color: rgb(25, 20, 188) !important;
    border-style: solid !important;
    border-width: 2px !important;
    }
    }
   }