.react-disabled-canvas {
  background:rgba(48, 112, 121, 0.05);
}

.react-calendar-timeline * {
    box-sizing: border-box; }
  
  .react-calendar-timeline .rct-outer {
    display: block;
    overflow: hidden;
    white-space: nowrap; }
  
  .react-calendar-timeline .rct-scroll {
    display: inline-block;
    white-space: normal;
    vertical-align: top;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-touch-action: none;
    touch-action: none; }
  
  .react-calendar-timeline .rct-item:hover {
    z-index: 88; }
  
  .react-calendar-timeline .rct-item .rct-item-content {
    position: sticky;
    position: -webkit-sticky;
    left: 0px;
    overflow: hidden;
    display: inline-block;
    border-radius: 2px;
    padding: 0 6px;
    height: 100%; }
  
  .react-calendar-timeline .rct-sidebar {
    overflow: hidden;
    white-space: normal;
    display: inline-block;
    vertical-align: top;
    position: relative;
    box-sizing: border-box;
    border-right: 0px solid #007bff; }
    .react-calendar-timeline .rct-sidebar.rct-sidebar-right {
      border-right: 2px solid #007bff;
      border-left: 1px solid #bbb; }
    .react-calendar-timeline .rct-sidebar .rct-sidebar-row {
      padding: 0 4px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-sizing: border-box;
      margin: 0;
      border-bottom: 1px solid #bbb; }
      .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
        background: rgba(0, 0, 0, 0.05); }
      .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
        background: transparent; }
  
  .react-calendar-timeline .rct-vertical-lines .rct-vl {
    position: absolute;
    border-left: 1px solid #bbb;
    z-index: 30; }
    .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
      border-left-width: 2px; }
    .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
      background: rgba(250, 246, 225, 0.5); }
  
  .react-calendar-timeline .rct-horizontal-lines {
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none; }
    .react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
    .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
      border-bottom: 1px solid #bbb;
      box-sizing: border-box;
      z-index: 40; }
    .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
      background: rgba(0, 0, 0, 0.05); }
    .react-calendar-timeline .rct-horizontal-lines .rct-hl-even {
      background: transparent; }
  
  .react-calendar-timeline .rct-cursor-line {
    position: absolute;
    width: 2px;
    background: #2196f3;
    z-index: 51; }
  
  .react-calendar-timeline .rct-dateHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    border-bottom: 1px solid #bbb;
    cursor: pointer;
    font-size: 14px;
    background-color: #f0f0f0;
    border-left: 2px solid #bbb; }
  
  .react-calendar-timeline .rct-dateHeader-primary {
    background-color: initial;
    border-left: 1px solid #bbb;
    border-right: 1px solid #bbb;
    color: #fff; }
  
  .react-calendar-timeline .rct-header-root {
    background: #007bff;
    border-bottom: 1px solid #bbb; }
  
  .react-calendar-timeline .rct-calendar-header {
    border: 1px solid #bbb; }
  