/* Sidebar.css */

.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.3); /* Overlay trasparente */
    z-index: 1; /* Imposta uno z-index inferiore rispetto alla sidebar */
  }
  
  .sidebar {
    background: white;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: auto;
    right: -250px;
    z-index: 3; /* Z-index superiore rispetto all'overlay */
    transition: right 0.3s ease;
  }

  .content {
    position: relative;
    z-index: 2; /* Z-index superiore rispetto all'overlay */
  }
  
  .sidebar.open {
    right: 0;
  }

  centered-text {
    display: inline-block;
}

 
  .sidebar-remaining-time {
    display: inline-block;
    font-weight: bold;
    padding: 8px;
    position: fixed;
    top: 35px;
    right: 0;
    margin-right: 3vw;
    transform: translate(-90%, -50%);
    z-index: 4; /* Z-index superiore rispetto alla sidebar */
  }
  
  
  .sidebar-button
   {
    position: fixed;
    top: 35px;
    right: 0;
    margin-right:20px;
    transform: translate(-32%, -50%);
    z-index: 4; /* Z-index superiore ris
    z-index: 4; /* Z-index superiore rispetto alla sidebar */
  }

  .help-button
   {
    position: fixed;
    top: 35px;
    right: 0;
    margin-right:20px;
    transform: translate(0%, -50%);
    z-index: 4; /* Z-index superiore ris
    z-index: 4; /* Z-index superiore rispetto alla sidebar */
  }

  

  
  .content {
    position: relative;
    z-index: 1; /* Z-index inferiore rispetto alla sidebar */
  }