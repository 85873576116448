

/* Contenitore del header */
.header-container {
  position: relative;
  width: 100%;
  height: 15vh;
}

.account-button-container {
  position: absolute;
  bottom: 8px;
  width: 10vw;
  height: auto;
  right: 10px;
}


.language-selector {
  position: absolute;
  bottom: -5px;
  width: 10vw;
  height: auto;
  right: 10px;
}


.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
   /*  object-position: top Allinea la parte superiore dell'immagine */
}

/* Immagini sovrapposte */
.overlay-image {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 80px; /* Altezza delle immagini sovrapposte */
}

.overlay-image.left {
  top: 25px;
  left: 15px; /* Posizionamento dell'immagine a sinistra */
  width:40%;
  height: auto;
}

.overlay-image.right {
  top: 25px;
  right: 0cm; /* Posizionamento dell'immagine a destra */
  width:40%;
  height: auto;
}

.overlay-image.left {
  top: 25px;
  left: 10px; /* Posizionamento dell'immagine a sinistra */
  width:40%;
  height: auto;
}

/* Contenuto della pagina */
.content {
  padding: 10px;
  text-align: center;
;
}

/* Contenitore del footer */
.footer-container {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-container {
  position: relative;
  width: 100%;
  height: 15vh;
}

.image-container {
  background-image: url('../../assets/riale_eu_footer_no_loghi.png');
  background-size: cover;         /* Assicura che l'immagine riempia l'intero contenitore */
  background-position: center;    /* Centra l'immagine */
  background-repeat: no-repeat;   /* Evita la ripetizione dell'immagine */
  padding: 5px;                  /* Spazio intorno al testo */
  color: white;
  text-align: left;
  font-size: "1.0em";
  font-weight: bold;
  /* text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7); */
}

.overlay-text {
  text-align: left;
}


/* Per schermi con altezza inferiore a 500px (come molti telefoni in orizzontale) */
@media (max-height: 500px) {
  .header-image-old {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 20vh; /* Modifica a 20vh per coerenza */
    object-fit: contain; /* Mantieni l'immagine visibile */
  }


.header-container {
  position: relative;
  width: 100%;
  height: 35vh;
}


  .header-image {
    width: 100%;         /* L'immagine occupa il 100% della larghezza del contenitore */
    height: 100%;        /* L'altezza si adatta al contenitore (20vh) */
    object-fit: cover;   /* L'immagine riempie l'intero contenitore mantenendo le proporzioni */
    max-width: 100%;     /* Assicura che l'immagine non superi mai la larghezza del contenitore */
  }

  .overlay-image.right {
    top: 25px;
    right: 0cm; /* Posizionamento dell'immagine a destra */
    width: 25%;
    height: auto;
  }
  
  .overlay-image.left {
    top: 25px;
    left: 10px; /* Posizionamento dell'immagine a sinistra */
    width: 25%;
    height: auto;
  }
  .footer-banner {
    max-height: 20vh;           /* Altezza massima di 20vh */
    width: auto;                /* Larghezza proporzionale all'altezza */
    height: auto;               /* Mantiene le proporzioni */
    object-fit: contain;        /* Mantiene l'immagine proporzionale senza tagliarla */
  }

  .account-button-container {
    right: 0px
  }
}