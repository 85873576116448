.hamburger-menu {
    position: absolute;
    left: 10px;
    bottom: 10px;
    width: "75vw"
  }
  
  .hamburger-icon {
   
    font-size: 24px;
    background: none;
    color: white;
    border: 1px solid white;
    cursor: pointer;

  }
  
  .menu {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: rgb(255, 255, 255);
    border: 1px solid #ccc;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .button-font {
    font-weight: bold;
    border: 0px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .separator {
    width: 100%;
    height: 0px;
    border: 1px solid #ccc;
    margin: 0px;
    padding: 0px;
  }

  @media (min-width: 768px) {
    .hamburger-icon {
      display: none;
    }
  
    .menu {
      position: static;
      display: block;
      box-shadow: none;
    }
  
    .menu ul {
      display: flex;
      gap: 20px;
    }
  
    .menu li {
      border-bottom: none;
    }
  }
  