
.footer-button
  {
   position: fixed;
   bottom: 0px;
   right: 0;
   margin-right:20px;
   transform: translate(0%, -50%);
   z-index: 4; /* Z-index superiore ris
   z-index: 4; /* Z-index superiore rispetto alla sidebar */
 }