.leaflet-popup {
    z-index: 1000 !important;
}

.leaflet-popup-content {
    word-wrap: break-word;
    max-width: 300px; /* Imposta una larghezza massima */
}

.leaflet-popup-content {
    max-height: 300px; /* Imposta un'altezza massima */
    overflow-y: auto; /* Abilita lo scroll verticale */
}
