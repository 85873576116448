.parent-expand-foo {
    background-color: rgb(179, 227, 167);
    cursor: pointer;
}

.expand-column {
    width: 60px !important; /* Aumenta la larghezza */
    text-align: center;
    cursor: pointer;
  }

  .expand-column-header {
    width: 60px !important; /* Imposta la larghezza della colonna di espansione */
    text-align: center;
    cursor: pointer;
  }
  
  .expand-column-cell {
    width: 120px !important; /* Imposta la larghezza delle celle */
    text-align: left;
    cursor: pointer;
  }